<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Login-->
      <b-col
        lg="6"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="10"
          md="6"
          lg="10"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            title-tag="h2"
            class="font-weight-bold mb-1"
          >
            Witaj w panelu pickmode 👋
          </b-card-title>
          <b-card-text class="mb-2">
            zalogouj się aby kontynuować
          </b-card-text>

          <!-- form -->
          <validation-observer ref="loginValidation">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent
            >
              <!-- email -->
              <b-form-group
                label="Email"
                label-for="login-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="login-email"
                    placeholder="Podaj adres email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">hasło</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                @click="validationForm"
              >
                zalogouj się
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            Logując się akceptujesz naszą <a href="https://pickmode.pl/Polityka-prywatnosci">politykę prywatności</a>
            <span>Pierwszy raz tutaj? </span>
            <a href="https://pickmode.pl/Rejestruj-Biznes">
              <span>&nbsp;Stwórz konto</span>
            </a>
          </b-card-text>
        </b-col>
      </b-col>

      <b-col
        lg="6"
        class="d-none d-lg-flex align-items-center p-5"
        style="background-size: cover; background-position: center center; background-repeat: no-repeat;"
        :style="{
          'background-image': 'url(' + sideImg + ')'
        }"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5" />
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import swal from 'sweetalert2'
import axios from 'axios'
import initPWA from '../helpers/ios/ios-pwa'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/pexels-david-geib-32687321.png'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/design.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    document.title = 'Pickmode - Logowanie'

    initPWA()
  },
  created() {
    if (localStorage.accessToken) {
      this.$router.push({ name: 'home' })
    }
  },
  methods: {
    validationForm() {
      axios.post(`${process.env.VUE_APP_API_URL}Login`, {
        email: this.userEmail,
        password: this.password,
      }).then(response => {
        if (response.data.token) {
          localStorage.setItem('accessToken', response.data.token.plainTextToken)

          if (response.data.employee) {
            this.$router.push({ name: 'EmployeeView' })
            return true
          }
          this.$router.push({ name: 'home' })
          return true
        }
        this.$bvToast.toast(response.data.message, {
          title: 'Error',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: true,
        })
      }).catch(err => {
        if (err.response.status === 401 && err.response.data.error === 'Please verify your email first') {
          swal.fire({
            title: 'Błąd',
            text: 'Email nie został zweryfikowany, proszę sprawdzić swoją pocztę wraz z folderem spam',
            icon: 'info',
          })
          return true
        }
        swal.fire({
          title: 'Błąd',
          text: 'Nieprawidłowy email lub hasło',
          icon: 'error',
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
