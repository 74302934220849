import Swal from 'sweetalert2'

const ifIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()

  return /iphone|ipad|ipod/.test(userAgent)
}

const initPWA = () => {
  // check in localStorage if user has already been asked to install the PWA
  const alreadyPrompted = localStorage.getItem('install-pwa')
  if (!ifIos() || alreadyPrompted) {
    return
  }

  Swal.fire({
    name: 'Czy chcesz zainstalować aplikację?',
    text: 'Mozesz dodać aplikację do ekranu głównego',
    icon: 'info',
    showCancelButton: true,
    confirmButtonText: 'Tak',
    cancelButtonText: 'Nie',
  }).then(result => {
    localStorage.setItem('install-pwa', true)
    if (result.isConfirmed) {
      Swal.fire({
        title: 'Dodaj aplikację do ekranu głównego',
        html: `
          <ol>
            <li>Wciśnij przycisk <strong>Udostępnij</strong></li>
            <li>Wybierz opcję <strong>Dodaj do ekranu głównego</strong></li>
            <li>Wciśnij <strong>Dodaj</strong></li>
          </ol>
        `,
        icon: 'info',
        confirmButtonText: 'OK',
      })
    }
  })
}

export default initPWA
